import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import {Store} from '@ngrx/store';
import { DetailsOpportuniteStore } from '../../store/details-opportunite.reducers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {


  infocomplementaire$: Observable<any> | null = null;
  currentLanguage: string;
  constructor(  private apiOpportunitService: ApiOpportunitService,
    private translate: TranslateService,) { }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.currentLanguage = this.translate.currentLang;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.currentLanguage = this.translate.currentLang;
      });
     });
  }
}
