<div class="card-4" >
    <div class="container">
        <div class="row details-nav">
            <div class="col-4 pr-0">
                <button class="button-style left-btn" [ngClass]="{'right-btn': currentLanguage === 'ar'}" routerLink="contrat-actuel">
                    {{"languages.opportunite.details.ContratActuel" | translate}}:
            </button>
            </div>
            <div class="col-4 px-0">
                <button class="button-style middle-btn" routerLink="information-complementaires">
                 {{"languages.opportunite.details.infoCompCampagne" | translate}}:
            </button>
            </div>
            <div class="col-4 pl-0">
                <button class="button-style " [ngClass]="{'left-btn': currentLanguage === 'ar', 'right-btn': currentLanguage !== 'ar'}" routerLink="information-specifiques">
                    {{"languages.opportunite.details.infoCompLigne" | translate}}:
            </button>
            </div>
        </div>
        <div class="row details-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
