<div class="row">
    <div class="col-12">
        <mat-accordion class="example-headers-align mb-2">
            <!-- Info Société-->
            <mat-expansion-panel class="expension-secondary-color mb-2" [expanded]="step === 0" (opened)="setStep(0)"
                hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title >
                        <h2 class="font-primary-geoprod col-10"> {{"languages.prospect.societe" | translate }}</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row w-100 " *ngIf="modeAjout">
                <!--     <div class="col-1">
                        <mat-spinner [diameter]="30"></mat-spinner>
                      </div> -->
                      <div class="col-12 w-100 d-flex flex-column align-items-end ml-auto text-nowrap">
                        <div class="d-flex ">
                         <mat-spinner class="mr-4 mt-1" [diameter]="25" *ngIf="loaderInfo" ></mat-spinner>

                        <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" (click)="showhidePopfirst()"  type="button"
                            class="add-btn-searchOpp" >
                            <i class="fa-solid fa-magnifying-glass fa fa-lg fa-flip-horizontal"></i>
                            &nbsp;
                            {{'languages.buttons.rechercherPar' | translate}}

                        </button>

                        <div class="dropdown-menu popup dropdown-menu-right" aria-labelledby="dropdownMenuButton"
                            [ngClass]="{'hidden': !isDropdownVisible}">

                            <div class="popup-content" (click)="stopClickPropagation($event)">
                                <div class="row mb-2">
                                    <button type="button" (click)="showhidePop()" class="close-button">&times;</button>
                                </div>
                                <div class="row mt-1"
                                    *ngFor="let form of formInfoHolder ;let index = index; let isFirst = first;">
                                    <div class="input-group  col-10">
                                        <select class="custom-select" name="key{{index}}" id="key{{index}}"
                                            [(ngModel)]="formInfoHolder[index].key"
                                            (ngModelChange)="setValues(index , 'key', $event)">
                                            <option value=""  disabled selected >Choisir une option</option>
                                            <option [disabled]="item.disabled" value="{{item.name}}"
                                                *ngFor="let item of listkeys">{{item.title}}</option>
                                        </select>
                                        <select class="custom-select" name="op{{index}}" id="op{{index}}"
                                            [(ngModel)]="formInfoHolder[index].operateur"
                                            (ngModelChange)="setValues(index , 'operateur ', $event)">
                                            <option value="=">{{'languages.opportunite.egale' | translate}}</option>
                                            <!-- <option value="!=">Different</option> -->
                                        </select>
                                        <input type="text"  placeholder="{{'languages.prospect.enterValue' | translate}}"
                                            class="form-control " name="val{{index}}" id="val{{index}}"
                                            (change)="setValues(index , 'value', $event)" />
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-start" *ngIf="isFirst">
                                        <button type="button" class="btn-dt-add " (click)="onAdd()">
                                            <i class="fa-solid fa-plus"></i> 
                                        </button>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-start" *ngIf="!isFirst">
                                        <button type="button" class="cancelBtn float-left"
                                            (click)="deleteSearch(index)">
                                            <img src="assets/icons/closeIcon.svg" class="iconClose">
                                        </button>
                                    </div>
                                </div>
                                <!--          <mat-form-field class="w-100 mb-3">
                                        <input matInput id="path{{index}}" name="path{{index}}"
                                            [ngModel]="formInfoHolder[index]"
                                            (change)="inputChange($event.target.value, index)" >

                                    </mat-form-field> -->

                                <div class="row w-100 d-flex justify-content-center mt-4">

                                    <div class=" mx-2  text-right ">
                                        <button (click)=" OnOpen()" class=" btn-dt-save-popup" data-toggle="modal"
                                            data-target="#listProspect" type="button">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                                            {{'languages.buttons.rechercher' | translate}}
                                        </button>

                                    </div>
                                       <div class=" mx-2 text-right ">
                                        <button class="btn-dt-cancel-popup" type="button" style="width:128px" (click)="clearModal()">
<i class="fa-solid fa-circle-xmark  mr-1 fa-lg"></i>
                                            {{'languages.buttons.annuler' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="row body ">
                    <div class="col-12">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 prospect-card-field">
                                    <div class="form-group-container">
                                        <p class="form-group-container-title"> {{"languages.prospect.identite" | translate }} </p>




                                        <div class="row">
                                            <form action="" [formGroup]="entrepriseForm"
                                                (change)="updateEntrepriseInfo()"
                                                [ngClass]="{'cover': !allowed_to_update}">
                                                <div class="row">
                                                    <!--   <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{countryLanguage.PROSPECT.SIREN}}</mat-label>
                                                            <input type="number" (keypress)="numberlength($event)" matInput placeholder="123 456 789" formControlName="siren" pattern="([0-9]{9})" (ngModelChange)="autoRemplirProspectPro($event)" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{countryLanguage.PROSPECT.SIRET}}</mat-label>
                                                            <input type="number" (keypress)="numberlengthSiret($event)" min="0" pattern="([0-9]{14})" maxlength="14" matInput placeholder="123 456 789 00001" formControlName="siret" autocomplete="off">
                                                        </mat-form-field>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{"languages.prospect.Effectif" | translate }}</mat-label>
                                                            <input (keypress)="numberOnly($event)" type="number" min="0"
                                                                matInput placeholder="0" formControlName="effectif"
                                                                autocomplete="off">

                                                        </mat-form-field>
                                                    </div>
                                                    <!--    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{countryLanguage.PROSPECT.NAF.LABEL}}</mat-label>
                                                            <input type="text" (keypress)="numberlengthNaf($event)" matInput [placeholder]="countryLanguage.PROSPECT.NAF.PLACEHOLDER" formControlName="naf" autocomplete="off">
                                                            <mat-error *ngIf="isValid('naf')"><span> {{countryLanguage.PROSPECT.NAF.LABEL}} invalide doit
                                                                    contenir {{countryLanguage.PROSPECT.NAF.PLACEHOLDER.length}} chiffres et un letter exemple :{{countryLanguage.PROSPECT.NAF.PLACEHOLDER}}
                                                                </span></mat-error>
                                                        </mat-form-field>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{"languages.prospect.RaisonSociale" | translate }}</mat-label>
                                                            <input type="text" matInput formControlName="RS" required
                                                                name="RS" autocomplete="off">
                                                            <mat-error *ngIf="isValid('RS')"><span> {{"languages.prospect.check_RaisonSociale" | translate }}</span></mat-error>

                                                        </mat-form-field>
                                                    </div>
                                                    <!--       <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Nom Commercial</mat-label>
                                                            <input type="text" matInput formControlName="nom_commercial" autocomplete="off">
                                                        </mat-form-field>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{"languages.prospect.ConventionCollective" | translate }}</mat-label>
                                                            <input type="text" matInput
                                                                formControlName="convention_collective"
                                                                placeholder="CCN CHR 3292" autocomplete="off">
                                                            <mat-error *ngIf="isValid('convention_collective')">
                                                                 {{"languages.prospect.check_convention" | translate }}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{"languages.prospect.Dirigeant" | translate }}</mat-label>
                                                            <input type="text" matInput formControlName="respensable"
                                                                autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width " appearance="standard">
                                                            <mat-label>{{"languages.prospect.Formejuridique" | translate }}</mat-label>

                                                            <mat-select formControlName="FJ"
                                                                (selectionChange)="updateEntrepriseInfo()">
                                                                <mat-option *ngFor="let f of FormeJuridique"
                                                                    [value]="f.id">{{f.libelle}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{"languages.prospect.NumTVA" | translate }}</mat-label>
                                                            <input type="number" matInput
                                                                formControlName="numero_tva_intra" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{"languages.opportunite.label_creationdate" | translate }}</mat-label>
                                                            <input placeholder="jj/mm/aaaa" [max]="minDate" matInput
                                                                readonly (dateChange)="creationDateChanged()"
                                                                [matDatepicker]="pickerCD"
                                                                formControlName="date_creation"
                                                                (click)="pickerCD.open()">
                                                            <mat-datepicker-toggle matSuffix [for]="pickerCD">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #pickerCD></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{"languages.prospect.Tranche-effectifs" | translate }}</mat-label>
                                                            <input type="text" matInput
                                                                formControlName="tranche_effectifs" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                                <div *ngIf="isfilled" class="col-xl-12 col-lg-12 prospect-card-field">


                                    <div class="form-group-container infoCompBlocPro " id ="infoCompPro" [ngClass]="{'cover': !allowed_to_update}">
                                        <p class="form-group-container-title">- Informations complémentaires </p>
                                        <formly-form (modelChange)="updateEntrepriseInfo()" [model]="model"
                                            [fields]="fields" [options]="options" [form]="form"></formly-form>

                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12 prospect-card-field">
                                    <form [formGroup]="entrepriseForm" [ngClass]="{'cover': !allowed_to_update}">
                                        <div class="form-group-container">
                                            <p class="form-group-container-title"> {{"languages.prospect.contact" | translate }} </p>
                                            <div class="row">

                                                <div class="col-12">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>{{"languages.prospect.email" | translate }}</mat-label>
                                                        <input type="email" matInput formControlName="mail" name="mail"
                                                            autocomplete="nofill">
                                                            <i class="fa-solid fa-at" matSuffix></i>
                                                       
                                                    </mat-form-field>
                                                    <div *ngIf="!entrepriseForm?.get('mail')?.valid && (entrepriseForm?.get('mail')?.dirty || entrepriseForm?.get('mail').touched)"
                                                        class="message-erreur">
<i class="fa-solid fa-triangle-exclamation"></i>
                                                        {{"languages.prospect.check_emailPro" | translate }}
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>{{"languages.prospect.web" | translate }}</mat-label>
                                                        <input type="url" matInput formControlName="web"
                                                            placeholder="http://www. ..." autocomplete="nofill">
                                                        <mat-error *ngIf="isValid('web')">{{"languages.prospect.check_URL" | translate }}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-12">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label class="ng2tel-field-label">{{"languages.prospect.phone" | translate }}</mat-label>
                                                        <input ng2TelInput matInput name="tel"
                                                            (keypress)="numberOnly($event)"
                                                            (intlTelInputObject)="telInputObject($event)" maxlength="14"
                                                            formControlName="tel"
                                                            [ng2TelInputOptions]="{initialCountry: telCountry}"
                                                            (hasError)="hasError($event)"
                                                            (ng2TelOutput)="getNumber($event,'tel')">
                                                        <i class="fa-solid fa-phone-flip float-right"></i>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!--Adresse-->
                                <div class="col-xl-6 col-lg-12 prospect-card-field">
                                    <form [formGroup]="entrepriseForm" [ngClass]="{'cover': !allowed_to_update}">
                                        <div class="form-group-container ">
                                            <p class="form-group-container-title"> {{"languages.prospect.Adresse" | translate }} </p>
                                            <div class="row">
                                                <!--Adress Auto Google API-->
                                                <div class="col-12">

                                                    <app-auto-complete-adresses [isReadOnlyAdresse]="allowed_to_update"
                                                        iconColor="#9b9b9b"
                                                        (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                                                    </app-auto-complete-adresses>
                                                </div>
                                                <!--Country-->
                                                <div class="col-6">

                                                    <mat-select-country appearance="standard" label="Pays"
                                                        class="full-width" [value]="defaultCountry" [itemsLoadSize]="5"
                                                        readonly>
                                                    </mat-select-country>
                                                </div>
                                                <!--Ville-->
                                                <div class="col-6">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>{{"languages.prospect.Ville" | translate }}</mat-label>
                                                        <input matInput type="text" formControlName="ville" readonly>
                                                    </mat-form-field>
                                                </div>
                                                <!--Rue-->
                                                <div class="col-4">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>{{"languages.prospect.Rue" | translate }}</mat-label>
                                                        <input matInput type="text" formControlName="streetName"
                                                            readonly>
                                                    </mat-form-field>
                                                </div>
                                                <!--N° rue-->
                                                <div class="col-4">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>{{"languages.prospect.streetNumber" | translate }}</mat-label>
                                                        <input type="number" matInput min="0"
                                                            (keypress)="numberOnly($event)" placeholder="0" readonly
                                                            size="8" formControlName="streetNumber">
                                                    </mat-form-field>


                                                </div>
                                                <!--Code Postal-->
                                                <div class="col-4">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>{{"languages.prospect.CodePostal" | translate }}</mat-label>
                                                        <input type="text" min="0" (keypress)="numberOnly($event)"
                                                            matInput placeholder="0" readonly formControlName="CP"
                                                            readonly>
                                                    </mat-form-field>

                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <!-- Info Interlocuteur -->
            <mat-expansion-panel class="expension-secondary-color mb-2" [expanded]="step === (1)" (opened)="setStep(1)"
                hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title >
                        <h2 class="font-primary-geoprod col-10"> {{"languages.prospect.interlocuteur" | translate }}</h2>
                    </mat-panel-title>
                    <mat-panel-description>
                        <!--Description-->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <app-prospect-form *ngIf="prospectSchema" [modeAjout]="modeAjout" [schemaValue]="prospectSchema" (onSelectToFillOldProspect)="prospectFinder($event)"
                    [index]="'0'" [isMainProspect]="true" [type]="type" [prospect]="prospectPro.mainProspect"
                    (onUpdateProspect)="updateProspect($event,-1)">
                </app-prospect-form>
            </mat-expansion-panel>
            <!--Ifo Employers-->
            <ng-container *ngFor="let prospect of prospectPro.listProspectsLier; let index =index">
                <mat-expansion-panel *ngIf="!prospect.deleted" class="expension-secondary-color mb-2"
                    [expanded]="step === (index+2)" (opened)="setStep(index+2)" hideToggle>
                    <mat-expansion-panel-header>
                        <div class="col-6">
                            <mat-panel-title>
                                <h2 class="font-primary-geoprod col-10"> {{"languages.prospect.collaborateurNum" | translate }}{{index + 1}}: </h2>
                            </mat-panel-title>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-2">
                            <mat-panel-description class="float-right">
                                <!--Description-->
                                <!-- <span (click)="deleteProspect(index+1)" style="margin-top: 19px;">
                                  <img src="assets/icons/trash-icon-red.svg">
                                </span> -->
                            </mat-panel-description>
                        </div>

                    </mat-expansion-panel-header>
                    <app-prospect-form *ngIf="prospectSchema" [modeAjout]="modeAjout" [schemaValue]="prospectSchema"
                        (onSelectToFillOldProspect)="prospectFinder($event)" [type]="1" [index]="(index+1).toString()"
                        [isMainProspect]="false" [prospect]="prospect"
                        (onUpdateProspect)="updateProspect($event,index+1)"></app-prospect-form>
                </mat-expansion-panel>
            </ng-container>
            <div *ngIf="addProspectBtn" class="row mt-2">
                <div class="col-12 text-right">
                    <button type="button" (click)="addProspect()" class="add-btn-em">
                        <i class="fa-solid fa-user-plus fa-icon-style fa-lg mr-2"></i>

                        <span>{{"languages.prospect.addCollaborateur" | translate }}</span>
                    </button>
                </div>
            </div>
        </mat-accordion>
    </div>
</div>


