<form (ngSubmit)="alertConfirmUpdateActive(myForm.form.valid, myForm._directives)" #myForm="ngForm">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9" style="margin-top: 8px">
                <p>
                      {{'languages.organisme.numOrganism' | translate}} :
                    <label class="label-card-info">
                        {{ initialorganisme.real_id }}
                    </label>
                </p>
                <p>
                     {{'languages.user.organismName' | translate}} :
                    <label class="label-card-info">
                        {{ initialorganisme.nom }}
                    </label>
                </p>
            </div>
            <div class="col-3 text-left">
                <p>
                     {{'languages.listing.Type' | translate}} :
                    <label class="label-card-info">
                        {{ initialorganisme.libelle_type }}
                    </label>
                </p>
                <p *ngIf="organisme.dossier != null">
                     {{'languages.listing.Dossier' | translate}} :
                    <label class="label-card-info">
                        {{ initialorganisme.dossier }}
                    </label>
                </p>
            </div>
        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
                <div class="icon-position">
                    <i class="fa-solid fa-icon-style fa-lg" [ngClass]="{'fa-arrow-right': currentLanguage === 'ar','fa-arrow-left': currentLanguage !== 'ar'}"></i>
                </div>
            </button>

            <div class="row">
                <div class="row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col" style="margin-right: 10px">
                        <button type="button" class="btn-load" id="clear" (click)="clearForm()">
                            <i class="fa-solid fa-rotate-right"></i>
                        </button>
                    </div>
                </div>
                <button class="btn-dt-save" type="submit" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>{{'languages.buttons.enregistrer' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="card-head px-10 py-5 border-0"
        style="margin-bottom: 8rem; padding-bottom: 8rem; border-radius: 20px; padding-right: 8rem; padding-left: 8rem">
        <div class="row">
            <div class="col-9">
                <span style="color: red">( * ) {{'languages.affaire.champsOblig' | translate}}</span>
            </div>
            <div class="col-3">
                <button type="button" class="btn-dt-link" (click)="goToConventions()" [disabled]="ModeAjout">
<i class="fa-solid fa-sitemap"></i>
                 {{'languages.organisme.conventionDistrib' | translate}}
                  </button>
            </div>
        </div>
        <mat-tab-group mat-align-tabs="center" style="z-index: 0" #tabs>
            <mat-tab label="{{'languages.organisme.infoOrganism' | translate}}">
                <div class="card px-5 py-5 border-0">
                    <div class="container">
                        <div class="d-flex justify-content-evenly" style="justify-content: space-evenly">
                            <div *ngIf="organisme_id">
                                <div>
                                    <label class="switch-m space-switch ml-3" >
                                        <input type="checkbox" [checked]="organisme.active == '1'" name="active"
                                            (change)="isActive($event)" />
                                        <span class="slider-m round"></span>
                                    </label>
                                    <label for=""> {{'languages.listing.Active' | translate}}</label>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <label class="switch-m space-switch ml-3" >
                                        <input type="checkbox" [checked]="organisme.acces_geoprod == '1'" name="active"
                                            (change)="isAuthorized($event)" />
                                        <span class="slider-m round"></span>
                                    </label>
                                    <label for="">{{'languages.organisme.accesGeoprod' | translate}} </label>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label class="switch-m space-switch ml-3" >
                                        <input type="checkbox" [checked]="organisme.beta == '1'" name="beta"
                                            (change)="isBeta($event)" />
                                        <span class="slider-m round"></span>
                                    </label>
                                    <label for="">Beta</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                     <!--        <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{countryLanguage.ORGANISME.SIREN}}</mat-label>
                                <input autocomplete="off" [(ngModel)]="organisme.siren" matInput pattern="([0-9]{9})"
                                    placeholder="123 456 789" min="0" maxlength="9" name="siren"
                                    (ngModelChange)="autoRemplirBySiren($event)" (keypress)="keyPressNumbers($event)" />
                            </mat-form-field> -->
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{countryLanguage.ORGANISME.SIREN}}</mat-label>
                                <input autocomplete="off" [(ngModel)]="organisme.siren" matInput pattern="([0-9]{9})"
                                    placeholder="123 456 789" min="0" maxlength="9" name="{{countryLanguage.ORGANISME.SIREN}}"
                                    (keypress)="keyPressNumbers($event)" />
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                    <mat-label> {{'languages.organisme.NomOrganisme' | translate}}</mat-label>
                                    <input autocomplete="off" type="text" matInput [(ngModel)]="organisme.nom"
                                        name="nom_organisme" required #nom="ngModel" />
                                </mat-form-field>
                                <div *ngIf="nom.invalid && (nom.dirty || nom.touched)" class="message-erreur">
                                    <div *ngIf="nom.errors.required">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                        {{'languages.produit.nomOblig' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label> {{'languages.listing.Type' | translate}}</mat-label>
                                    <mat-select (selectionChange)="getLibelleId($event)" name="id_type"
                                        [(ngModel)]="organisme.id_type" required #type="ngModel">
                                        <mat-option *ngFor="let type of listTypeOrganisme" [value]="type.id">
                                            {{ type.libelle }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="type.invalid && (type.dirty || type.touched)" class="message-erreur">
                                    <div *ngIf="type.errors.required">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                         {{'languages.affaire.typeOblig' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{countryLanguage.ORGANISME.SIRET}}</mat-label>
                                <input autocomplete="off" min="0" pattern="([0-9]{14})" maxlength="14" name="siret"
                                    [(ngModel)]="organisme.siret" matInput placeholder="123 456 789 00001"
                                    (keypress)="keyPressNumbers($event)" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                                <div class="form-group">
                                    <ng-container *ngIf="loaderListCompagnie">
                                        <span class="mr-3">
                                            <mat-spinner [diameter]="15"></mat-spinner>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="campagnie.length > 0">
                                        <mat-form-field appearance="fill" class="example-chip-list"
                                            style="width: 100%;">
                                            <mat-label style="font-size: 13px"> {{'languages.organisme.Campagne' | translate}}</mat-label>
                                            <mat-chip-list #chipListC aria-label="campagnie selection">
                                                <mat-chip *ngFor="let campagnie of campagnieSelected"
                                                    [selectable]="selectable" [removable]="removable" name="campagnes"
                                                    (removed)="removeCampagne(campagnie)">
                                                    {{ campagnie }}
              
                                                    <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray"></i>
                                                </mat-chip>
                                                <input placeholder="{{'languages.organisme.addCampgagne' | translate}}"  #campagneInput
                                                    [formControl]="campagneCtrl" [matAutocomplete]="Autocomplete"
                                                    [matChipInputFor]="chipListC"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    (matChipInputTokenEnd)="addCampagne($event)" />
                                            </mat-chip-list>
                                            <mat-autocomplete #Autocomplete="matAutocomplete"
                                                (optionSelected)="selectedCampagne($event)">
                                                <mat-option *ngFor="let campagnie of filteredCampagne | async"
                                                    [value]="campagnie.id">
                                                    {{ campagnie.libelle }}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                                <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label> {{'languages.organisme.OrganismeMaster' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="organisme.organisme_master" required
                                        #nom_organisme_master="ngModel" name="nom_organisme_master">
                                        <mat-option *ngFor="let item of Organismes" [value]="item.id">
                                            {{ item.nom }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                                <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                    <mat-label> {{'languages.listing.Dossier' | translate}}</mat-label>
                                    <input autocomplete="off" type="text" matInput [(ngModel)]="organisme.dossier"
                                        name="dossier" #dossier="ngModel" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-6">
                            <label class="label-robot"> {{'languages.organisme.Robot' | translate}} </label>
                            <ng-autocomplete [data]="userList" [searchKeyword]="'nom_user'"
                                [itemTemplate]="itemListUser" name="id_robot" [(ngModel)]="initial_Robot"
                                (selected)="selectRobot($event)" [notFoundTemplate]="notFoundListUser">
                            </ng-autocomplete>

                            <ng-template #itemListUser let-item>
                                <a [innerHTML]="item.nom_user"></a>
                            </ng-template>

                            <ng-template #notFoundListUser let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row">
                        <!--
  <mat-form-field class="w-100" appearance="fill">
  <mat-label>Robot</mat-label>
  <input type="text" matInput [(ngModel)]="initial_Robot" [formControl]="affecterUserFormControl" name="id_robot" [matAutocomplete]="autoRobot" >
  <mat-autocomplete autoActiveFirstOption [displayWith]="displayVisaFn" #autoRobot="matAutocomplete">
  <mat-option *ngFor="let option of listVisaFilter | async" [value]="option">
  {{option.libele}}
  </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="affecterUserFormControl.hasError('invalidAutocompleteObject') && !affecterUserFormControl.hasError('required')" >
  <i class="fa-solid fa-triangle-exclamation"></i> Merci de vérfier la visa
  </mat-error>
  </mat-form-field>
  -->

                        <div class="col-12">
                            <div class="form-group">
                                <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                    <mat-label> {{'languages.organisme.descriptionFicheConseil' | translate}}</mat-label>
                                    <textarea name="description_ficheconseil" matInput
                                        [(ngModel)]="organisme.description_ficheconseil"
                                        placeholder="{{'languages.organisme.descriptionFicheConseil' | translate}}"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'languages.organisme.coordonnePro' | translate}}">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.prospect.phone' | translate}}</mat-label>
                                <input autocomplete="chrome-off" type="text" matInput name="telephone"
                                    [(ngModel)]="organisme.tel" #tel="ngModel" (keypress)="keyPressNumbers($event)" />
                                <i class="fa-solid fa-phone-flip mb-1" matSuffix ></i>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.prospect.email' | translate}}</mat-label>
                                <input autocomplete="chrome-off" type="email" matInput name="mail"
                                    placeholder="exemple@email.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    [(ngModel)]="organisme.mail" #mail="ngModel" />
                                <i matSuffix class="fa-solid fa-envelope"></i>
                                <mat-error *ngIf="mail.invalidOnOpen && (mail.dirty || mail.touched)">
                                    <mat-error *ngIf="mail.errors.pattern">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                        {{'languages.communication.EmailInvalid' | translate}}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.organisme.phoneGestion' | translate}}</mat-label>
                                <input autocomplete="chrome-off" type="text" matInput name="telephone_gestion"
                                    [(ngModel)]="organisme.tel_gestion" (keypress)="keyPressNumbers($event)" />
                                <i class="fa-solid fa-phone-flip " matSuffix ></i>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.organisme.emailGestion' | translate}}</mat-label>
                                <input autocomplete="chrome-off" type="email" matInput name="mail_gestion"
                                    placeholder="exemple@email.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    [(ngModel)]="organisme.mail_gestion" #mail_gestion="ngModel" />
                                <i class="fa-solid fa-envelope" matSuffix></i>
                                <mat-error *ngIf="mail_gestion.invalid && (mail_gestion.dirty || mail_gestion.touched)">
                                    <mat-error *ngIf="mail_gestion.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                                         {{'languages.communication.EmailInvalid' | translate}}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.prospect.Formejuridique' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="form_juridique"
                                    [(ngModel)]="organisme.form_juridique" #form_juridique="ngModel" />
                                <i matSuffix class="fa-solid fa-scale-balanced"></i>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label>{{countryLanguage.ORGANISME.RCS}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="rcs" [(ngModel)]="organisme.rcs"
                                    #rcs="ngModel" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                         <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                            <mat-label>{{'languages.prospect.Adresse' | translate}} </mat-label>
                            <input autocomplete="off" type="text" matInput name="address"
                                [(ngModel)]="organisme.adresse" #adresse="ngModel" />
                        </mat-form-field>
                    </div>
                     <!--    <app-auto-complete-adresses [isReadOnlyAdresse]="allowed_to_update" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                        </app-auto-complete-adresses>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.prospect.Ville' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="ville"
                                    [(ngModel)]="organisme.ville" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.prospect.CodePostal' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="code_postal"
                                    [(ngModel)]="organisme.code_postal" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.communication.rueNUM' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="street"
                                    [(ngModel)]="organisme.street" />
                            </mat-form-field>
                        </div>
                    </div> -->

                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.organisme.CodeIntermediaire' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="code_intermediaire"
                                    [(ngModel)]="organisme.code_intermediaire" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.organisme.Site' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="site" [(ngModel)]="organisme.site"
                                    #site="ngModel" />
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label>{{countryLanguage.ORGANISME.ORIAS}}</mat-label>
                                <input autocomplete="off" type="text" matInput name="orias"
                                    [(ngModel)]="organisme.orias" #orias="ngModel" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.organisme.URLCGU' | translate}}</mat-label>
                                <input autocomplete="chrome-off" type="url" matInput name="url_cgu"
                                    [(ngModel)]="organisme.url_cgu"
                                    pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                                    #url_cgu="ngModel" />
                                <mat-error *ngIf="url_cgu.invalid && (url_cgu.dirty || url_cgu.touched)"
                                    class="message-erreur">
                                    <mat-error *ngIf="url_cgu.errors.pattern">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                        {{'languages.role.formLinkInvalid' | translate}}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field class="full-width" appearance="fill" style="width: 100%">
                                <mat-label> {{'languages.organisme.URLPrivacy' | translate}}</mat-label>
                                <input autocomplete="chrome-off" type="url" matInput name="url_privacy"
                                    [(ngModel)]="organisme.url_privacy"
                                    pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                                    #url_privacy="ngModel" />
                                <mat-error *ngIf="url_privacy.invalid && (url_privacy.dirty || url_privacy.touched)"
                                    class="message-erreur">
                                    <mat-error *ngIf="url_privacy.errors.pattern">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                      {{'languages.role.formLinkInvalid' | translate}}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>{{'languages.organisme.Identifiantexterne' | translate}}</mat-label>
                                <input type="text" matInput [(ngModel)]="organisme.id_externe" name="id_externe">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <!-- <mat-tab label="Paramètrage" style="height: 31px">
  <div class="row mt-5">
  <div class="col-md-6 col-sm-12" >
  <div class="row">
  <div class="col-md-6">
  <div class="form-group">
  <label for="" style="color: #525050;">Pré Payé</label>
  </div>
  </div>
  <div class="col-md-6">
  <div class="form-group">
  <label class="switch-m space-switch">
  <input type="checkbox" [checked]="organisme.pre_pay == '1'" name="pre_pay"
  (change)="isPrePaye($event)" value="1">
  <span class="slider-m round"></span>
  </label>
  </div>
  </div>
  </div>
  </div>
  <div class="col-md-6 col-sm-12" *ngIf="organisme_id">
  <div class="row">
  <div class="col-md-6">
  <div class="form-group">
  <label class="title" style="color: #525050;">Active</label>
  </div>
  </div>
  <div class="col-md-6">
  <div class="form-group">
  <label class="switch-m space-switch" >
  <input type="checkbox" [checked]="organisme.active =='1'" name="active"
  (change)="isActive($event)">
  <span class="slider-m round"></span>
  </label>
  </div>
  </div>
  </div>
  </div>
  </div>

  </mat-tab> -->
            <mat-tab label="{{'languages.organisme.Couleursthemes' | translate}}" style="height: 31pc">
                <div class="row mt-5">
                    <div class="col-md-6 col-sm-12">
                        <label> {{'languages.organisme.Favicon' | translate}} <small> ( 32 pixels x 32 pixels ) </small> </label>
                        <div class="row">
                            <div class="col-2 pt-1 px-3  border border-primary" style="width: 100px">
                                <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                            </div>
                            <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                <input type="file" id="idfav" (change)="getLogo($event, 'favicon')" hidden
                                    name="favicon" [(ngModel)]="organisme.favicon" accept="image/*" />
                                <label for="idfav" class="select-label py-3 pl-4">  {{'languages.organisme.selectFavicon' | translate}} </label>
                            </div>
                            <div class="col-2 pt-3 pl-3  border border-primary">
                                <img class="img" data-toggle="modal" data-target="#Favicon" *ngIf="!showF"
                                    src="{{ favicon }}" />
                                <img class="img" data-toggle="modal" data-target="#Favicon" *ngIf="showF"
                                    src="{{ show_favicon }}" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <label> {{'languages.organisme.Logo' | translate}} <small> ( 168 pixels x 70 pixels ) </small></label>
                        <div class="row">
                            <div class="col-2 pt-1 px-3  border border-primary">
                                <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                            </div>
                            <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                <input type="file" id="idlogo" (change)="getLogo($event, 'logo')" hidden name="logo"
                                    [(ngModel)]="logo" accept="image/*" />
                                <label for="idlogo" class="select-label py-3 pl-4">  {{'languages.organisme.selectLogo' | translate}} </label>
                            </div>
                            <div class="col-2 pt-3 pl-3  border border-primary">
                                <img class="img" data-toggle="modal" data-target="#Logo" *ngIf="!show"
                                    src="{{ logo }}" />
                                <img class="img" data-toggle="modal" data-target="#Logo" *ngIf="show"
                                    src="{{ show_logo }}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="card bg-white border-5 p-3 center">
                        <div class="card-doc-title ml-3 row">
                            <h4> {{'languages.role.espaceDistrib' | translate}}</h4>
                        </div>
                        <div class="row mt-3">
                            <div class="col-6">
                                <div class="form-group">
                                    <mat-form-field appearance="fill" class="input w-100">
                                        <mat-label>{{'languages.organisme.couleurTheme' | translate}}</mat-label>
                                        <mat-select matNativeControl [(ngModel)]="couleur_theme" name="couleur_theme">
                                            <mat-option value="{{ item.name }}" *ngFor="let item of listTheme">
                                                <span class="dot"
                                                    [ngStyle]="{ 'background-color': item.color }"></span>{{ item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <mat-form-field appearance="fill" class="input w-100">
                                        <mat-label> {{'languages.organisme.Layout' | translate}}</mat-label>
                                        <mat-select matNativeControl [(ngModel)]="loyout" name="loyout">
                                            <mat-option value="{{ item }}" *ngFor="let item of listLayout">{{ item }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-6">
                                <div class="form-group">
                                    <mat-form-field appearance="fill" class="input w-100">
                                        <mat-label> {{'languages.organisme.Mode' | translate}}</mat-label>
                                        <mat-select [(ngModel)]="mode" name="mode">
                                            <mat-option value="{{ item }}" *ngFor="let item of listSchema"> {{ item }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label> {{'languages.organisme.fondImage' | translate}}</label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="idImage" (change)="getLogo($event, 'backgoundImage')"
                                            hidden name="background_images" [(ngModel)]="background_images"
                                            accept="image/*" />
                                        <label for="idImage" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}}</label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img" data-toggle="modal" data-target="#backgoundImage"
                                            *ngIf="!showB" src="{{ background_images }}" />
                                        <img class="img" data-toggle="modal" data-target="#backgoundImage" *ngIf="showB"
                                            src="{{ show_backgoundImage }}" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field class="w-100">
                                    <mat-label> {{'languages.organisme.colorone' | translate}}</mat-label>
                                    <input matInput [ngxMatColorPicker]="pickerDis" placeholder="{{'languages.organisme.colorone' | translate}}"
                                        [(ngModel)]="couleurDist1" name="couleurDist1" />
                                    <ngx-mat-color-toggle matSuffix [for]="pickerDis"
                                        [ngStyle]="{ color: couleurDist1 }"></ngx-mat-color-toggle>
                                    <ngx-mat-color-picker #pickerDis></ngx-mat-color-picker>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-card class="mt-3">
                            <div class="row  full-width">
                                <mat-tab-group mat-align-tabs="center" mat-stretch-tabs
                                    class="full-width demo-tab-group">
                                    <mat-tab label="Liste des offres">
                                        <div class="row ml-3 mt-3">
                                            <span class="mt-3" style="color: red">{{'languages.organisme.numberMaximEight' | translate}}</span>
                                        </div>

                                        <div class="row mt-3 " style="justify-content: center;">

                                            <div class="example-container">
                                                <div cdkDropList #todoList="cdkDropList"
                                                    [cdkDropListData]="listColumnOffre"
                                                    [cdkDropListConnectedTo]="[doneList]" class="example-list"
                                                    (cdkDropListDropped)="drop($event)">
                                                    <div class="example-box" *ngFor="let item of listColumnOffre"
                                                        cdkDrag>


                                                        <span>
                                                                <i class="fa-solid fa-maximize fa fa-2x mr-5   drop"></i>
                                                            {{item.libelle}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="example-container">
                                                <div cdkDropList #doneList="cdkDropList"
                                                    [cdkDropListData]="listColumnOffreDone"
                                                    [cdkDropListConnectedTo]="[todoList]" class="example-list"
                                                    (cdkDropListDropped)="drop($event)">
                                                    <div class="example-box" *ngFor="let item of listColumnOffreDone"
                                                        cdkDrag>
                                                        <i class="fa-solid fa-maximize fa fa-2x mr-5   drop"></i>

                                                        {{item.libelle}}
                                                        <button mat-icon-button type="button"
                                                            (click)="deleleItem(item.libelle ,'offre')">
                                                            <img src="assets/icons/trash-icon-red.svg">

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab label="liste des Contrats">
                                        <div class="row ml-3 mt-3">
                                            <span class="mt-3" style="color: red"> 
                                                {{'languages.organisme.numberMaximEight' | translate}}</span>
                                        </div>
                                        <div class="row mt-3" style="justify-content: center;">
                                            <div class="example-container">
                                                <div cdkDropList #todoListContrat="cdkDropList"
                                                    [cdkDropListData]="listColumnContrat"
                                                    [cdkDropListConnectedTo]="[doneListContrat]" class="example-list"
                                                    (cdkDropListDropped)="drop($event)">
                                                    <div class="example-box" *ngFor="let item of listColumnContrat"
                                                        cdkDrag>
                                                        <span><i class="fa-solid fa-maximize fa fa-2x mr-5   drop"></i>
                                                            {{item.libelle}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="example-container">

                                                <div cdkDropList #doneListContrat="cdkDropList"
                                                    [cdkDropListData]="listColumnContratDone"
                                                    [cdkDropListConnectedTo]="[todoListContrat]" class="example-list"
                                                    (cdkDropListDropped)="dropListeContrat($event)">
                                                    <div class="example-box"
                                                        *ngFor="let item of listColumnContratDone ; let index = index;"
                                                        cdkDrag>
                                                       
                                                            <i class="fa-solid fa-maximize fa fa-2x drop"></i>

                                                        {{item.libelle}}
                                                        <button mat-icon-button type="button"
                                                            (click)="deleleItem(item.libelle ,'contrat')">
                                                            <img src="assets/icons/trash-icon-red.svg">

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </mat-tab>

                                </mat-tab-group>

                            </div>
                        </mat-card>

                    </div>
                </div>

                <div class="row mt-5">
                    <div class="card bg-white border-5 p-3">
                        <div class="card-doc-title ml-3 row">
                            <h4> {{'languages.organisme.espaceClient' | translate}}</h4>
                        </div>
                        <div class="row mt-3">
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorone' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker" placeholder="{{'languages.organisme.colorone' | translate}}"
                                            [(ngModel)]="couleur_principale" name="couleur_principale" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker"
                                            [ngStyle]="{ color: couleur_principale }"></ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label> {{'languages.organisme.colorTwo' | translate}} </mat-label>
                                        <input matInput [ngxMatColorPicker]="pickerP" placeholder="{{'languages.organisme.colorTwo' | translate}}"
                                            [(ngModel)]="couleur_secondaire" name="couleur_secondaire" />
                                        <ngx-mat-color-toggle matSuffix [for]="pickerP"
                                            [ngStyle]="{ color: couleur_secondaire }"></ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #pickerP></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label> {{'languages.organisme.colorThree' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="pickerCouleur3" placeholder="{{'languages.organisme.colorThree' | translate}}"
                                            [(ngModel)]="couleur_client1" name="couleur_client1" />
                                        <ngx-mat-color-toggle matSuffix [for]="pickerCouleur3"
                                            [ngStyle]="{ color: couleur_client1 }"></ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #pickerCouleur3></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorFour' | translate}} </mat-label>
                                        <input matInput [ngxMatColorPicker]="pickerCouleur4" placeholder="{{'languages.organisme.colorFour' | translate}}"
                                            [(ngModel)]="couleur_client2" name="couleur_client2" />
                                        <ngx-mat-color-toggle matSuffix [for]="pickerCouleur4"
                                            [ngStyle]="{ color: couleur_client2 }"></ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #pickerCouleur4></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorFive' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="pickerCouleur5" placeholder="{{'languages.organisme.colorFive' | translate}}"
                                            [(ngModel)]="couleur_client3" name="couleur_client3" />
                                        <ngx-mat-color-toggle matSuffix [for]="pickerCouleur5"
                                            [ngStyle]="{ color: couleur_client3 }"></ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #pickerCouleur5></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field appearance="fill" class="input mb-10">
                                        <mat-label> {{'languages.organisme.Fontfamily' | translate}}</mat-label>
                                        <mat-select [(ngModel)]="font_family" name="font_family">
                                            <mat-option value="{{ item.family }}" *ngFor="let item of listFont"
                                                [ngStyle]="{ 'font-family': item.family }">
                                                {{ item.family }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="card bg-white border-5 p-3 center">
                        <div class="card-doc-title ml-3 row">
                            <h4> {{'languages.organisme.parcoursSouscription' | translate}}</h4>
                        </div>
                        <mat-label> {{'languages.organisme.colors' | translate}}</mat-label>
                        <div class="row mt-3">
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorone' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker1" placeholder="{{'languages.organisme.colorone' | translate}}"
                                            [(ngModel)]="couleur1" name="couleur1" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker1" [ngStyle]="{ color: couleur1 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker1></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorTwo' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker2" placeholder="{{'languages.organisme.colorTwo' | translate}}"
                                            [(ngModel)]="couleur2" name="couleur2" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker2" [ngStyle]="{ color: couleur2 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker2></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorThree' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker3" placeholder="{{'languages.organisme.colorThree' | translate}}"
                                            [(ngModel)]="couleur3" name="couleur3" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker3" [ngStyle]="{ color: couleur3 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker3></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorFour' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker4" placeholder="{{'languages.organisme.colorFour' | translate}}"
                                            [(ngModel)]="couleur4" name="couleur4" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker4" [ngStyle]="{ color: couleur4 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker4></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorFive' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker5" placeholder="{{'languages.organisme.colorFive' | translate}}"
                                            [(ngModel)]="couleur5" name="couleur5" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker5" [ngStyle]="{ color: couleur5 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker5></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label> {{'languages.organisme.colorSix' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker6" placeholder="{{'languages.organisme.colorSix' | translate}}"
                                            [(ngModel)]="couleur6" name="couleur6" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker6" [ngStyle]="{ color: couleur6 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker6></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'languages.organisme.colorSeven' | translate}} </mat-label>
                                        <input matInput [ngxMatColorPicker]="picker7" placeholder="{{'languages.organisme.colorSeven' | translate}}"
                                            [(ngModel)]="couleur7" name="couleur7" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker7" [ngStyle]="{ color: couleur7 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker7></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label> {{'languages.organisme.colorEight' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker8" placeholder="{{'languages.organisme.colorEight' | translate}}"
                                            [(ngModel)]="couleur8" name="couleur8" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker8" [ngStyle]="{ color: couleur8 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker8></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label> {{'languages.organisme.colorNine' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker9" placeholder="{{'languages.organisme.colorNine' | translate}}"
                                            [(ngModel)]="couleur9" name="couleur9" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker9" [ngStyle]="{ color: couleur9 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker9></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label> {{'languages.organisme.colorTen' | translate}}</mat-label>
                                        <input matInput [ngxMatColorPicker]="picker0" placeholder="{{'languages.organisme.colorTen' | translate}}"
                                            [(ngModel)]="couleur0" name="couleur0" />
                                        <ngx-mat-color-toggle matSuffix [for]="picker0" [ngStyle]="{ color: couleur0 }">
                                        </ngx-mat-color-toggle>
                                        <ngx-mat-color-picker #picker0></ngx-mat-color-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <mat-label> {{'languages.organisme.Fontfamily' | translate}}</mat-label>
                        <div class="row mt-3">
                            <div class="col-4">
                                <div class="form-group">
                                    <mat-form-field appearance="fill" class="input mb-10">
                                        <mat-label>{{'languages.organisme.Fontfamily' | translate}} </mat-label>
                                        <mat-select [(ngModel)]="font_familyy" name="font_familyy">
                                            <mat-option value="{{ item.family }}" *ngFor="let item of listFont"
                                                [ngStyle]="{ 'font-family': item.family }">
                                                {{ item.family }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <label> {{'languages.organisme.images' | translate}}</label>
                        <div class="row mt-3">
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row w-100">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image1" (change)="onFileChange($event, 0, 'image1')"
                                            hidden [(ngModel)]="image1" name="image1" accept="image/*" />
                                        <label for="image1" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[0]"
                                            [src]="image1" (click)="sendIndex(0, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[0]" [src]="img1" (click)="sendIndex(0, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image2" (change)="onFileChange($event, 1, 'image2')"
                                            hidden [(ngModel)]="image2" name="image2" accept="image/*" />
                                        <label for="image2" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[1]"
                                            [src]="image2" (click)="sendIndex(1, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[1]" [src]="img2" (click)="sendIndex(1, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image3" (change)="onFileChange($event, 2, 'image3')"
                                            hidden accept="image/*" [(ngModel)]="image3" name="image3" />
                                        <label for="image3" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[2]"
                                            [src]="image3" (click)="sendIndex(2, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[2]" [src]="img3" (click)="sendIndex(2, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image4" (change)="onFileChange($event, 3, 'image4')"
                                            accept="image/*" hidden [(ngModel)]="image4" name="image4" />
                                        <label for="image4" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[3]"
                                            [src]="image4" (click)="sendIndex(3, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[3]" [src]="img4" (click)="sendIndex(3, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image5" (change)="onFileChange($event, 4, 'image5')"
                                            accept="image/*" hidden [(ngModel)]="image5" name="image5" />
                                        <label for="image5" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[4]"
                                            [src]="image5" (click)="sendIndex(4, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[4]" [src]="img5" (click)="sendIndex(4, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image6" (change)="onFileChange($event, 5, 'image6')"
                                            hidden [(ngModel)]="image6" name="image6" accept="image/*" />
                                        <label for="image6" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[5]"
                                            [src]="image6" (click)="sendIndex(5, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[5]" [src]="img6" (click)="sendIndex(5, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image7" (change)="onFileChange($event, 6, 'image7')"
                                            hidden [(ngModel)]="image7" name="image7" accept="image/*" />
                                        <label for="image7" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[6]"
                                            [src]="image7" (click)="sendIndex(6, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[6]" [src]="img7" (click)="sendIndex(6, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3 border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image8" (change)="onFileChange($event, 7, 'image8')"
                                            hidden [(ngModel)]="image8" name="image8" accept="image/*" />
                                        <label for="image8" class="select-label py-3 pl-4">{{'languages.organisme.selectImage' | translate}}</label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[7]"
                                            [src]="image8" (click)="sendIndex(7, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[7]" [src]="img8" (click)="sendIndex(7, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image9" (change)="onFileChange($event, 8, 'image9')"
                                            hidden [(ngModel)]="image9" name="image9" accept="image/*" />
                                        <label for="image9" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[8]"
                                            [src]="image9" (click)="sendIndex(8, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[8]" [src]="img9" (click)="sendIndex(8, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label></label>
                                <div class="row">
                                    <div class="col-2 pt-1 px-3  border border-primary">
                                        <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                                    </div>
                                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                        <input type="file" id="image0" (change)="onFileChange($event, 9, 'image10')"
                                            hidden [(ngModel)]="image0" name="image0" accept="image/*" />
                                        <label for="image0" class="select-label py-3 pl-4"> {{'languages.organisme.selectImage' | translate}} </label>
                                    </div>
                                    <div class="col-2 pt-3 pl-3  border border-primary">
                                        <img class="img w-100" data-toggle="modal" data-target="#Image" *ngIf="list2[9]"
                                            [src]="image0" (click)="sendIndex(9, 'getImage')" />
                                        <img class="img w-100" data-toggle="modal" data-target="#Image"
                                            *ngIf="!list2[9]" [src]="img0" (click)="sendIndex(9, 'getImage2')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'languages.prospect.infoComp' | translate}}" *ngIf="appear">
                <div class="mt-3">
                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
                  </div>

            </mat-tab>

        </mat-tab-group>
    </div>
    <div class="modal fade" id="Logo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content" style="width: 100%">
                <div class="modal-header">
                    <h2 style="margin-left: 15px"> {{'languages.organisme.Logo' | translate}}</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="justify-content: center">
                        <img class="img imgModal w-100" data-toggle="modal" data-target="#Logo" *ngIf="!show"
                            src="{{ organisme.logo }}" />
                        <img class="img imgModal w-100" data-toggle="modal" data-target="#Logo" *ngIf="show"
                            src="{{ show_logo }}" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="Favicon" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content" style="width: 100%">
                <div class="modal-header">
                    <h2 style="margin-left: 15px"> {{'languages.organisme.Favicon' | translate}}</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="justify-content: center">
                        <img class="img imgModal w-100 h-100" data-toggle="modal" data-target="#Favicon" *ngIf="!showF"
                            src="{{ organisme.favicon }}" />
                        <img class="img imgModal w-100 h-100" data-toggle="modal" data-target="#Favicon" *ngIf="showF"
                            src="{{ show_favicon }}" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="backgoundImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content" style="width: 100%">
                <div class="modal-header">
                    <h2 style="margin-left: 15px"> {{'languages.organisme.backgroundImage' | translate}}</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="justify-content: center">
                        <img class="img w-100 h-100 imgModal" data-toggle="modal" data-target="#Favicon" *ngIf="!showB"
                            src="{{ organisme.background_image }}" />
                        <img class="img w-100 h-100 imgModal" data-toggle="modal" data-target="#Favicon" *ngIf="showB"
                            src="{{ show_backgoundImage }}" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="Image" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content" style="width: 100%">
                <div class="modal-header">
                    <h2 style="margin-left: 15px"> {{'languages.organisme.Image' | translate}}</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="justify-content: center">
                        <img class="img w-100 h-100" data-toggle="modal" data-target="#Favicon" *ngIf="showImage1"
                            src="{{ getlistImage[index] }}" />
                        <img class="img w-100 h-100" data-toggle="modal" data-target="#Favicon" *ngIf="!showImage1"
                            src="{{ listImage[index] }}" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> {{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>













<!-- <div class="row mt-3 justify-content-center listTab" >
    <div class="col-3 mt-3">

            <button style="width: 200px ;" type="button" mat-raised-button color="primary" [matMenuTriggerFor]="menu"
            (click)="addNew()">
            Add New <i class="material-icons">add_circle_outline</i>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let item of listColumnOffre">{{item}}</button>

        </mat-menu>
    </div>
        <div class="col mt-3">
            <div cdkDropList class="example-list mt-20" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let movie of movies, index as i" cdkDrag>
                    <div class="drag-index">{{i+1}}</div>
                    <div class="w-10 drag-icon">
                        <i class="material-icons" cdkDragHandle>reorder</i>
                    </div>
                    <div class="w-100 drag-content">
                        {{movie}}
                    </div>
                    <mat-checkbox (change)="movie.isDisable = !movie.isDisable">Click and drop only through icon</mat-checkbox>
                  </div>
                    <div class="w-10 delete-icon">
                        <button mat-icon-button type="button" (click)="delete(i)">
                            <i class="material-icons" >delete_outline</i>
                        </button>
                    </div>
                </div>
            </div>
    </div>


</div> -->
