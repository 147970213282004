import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';

import {
  Courrier,
  Createur,
  Destinataire,
  FilterMaileva,
  Expediteur,
  Statut,
} from '../../../../../entity/Maileva/FilterMaileva';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { FilterHistoryService } from 'src/app/services/ApiFilterSave/filter-history.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { FiltersCourrierComponent } from '../filters-courrier/filters-courrier.component';
import { FiltersCreateursComponent } from '../filters-createurs/filters-createurs.component';
import { FiltersDistinataireComponent } from '../filters-distinataire/filters-distinataire.component';
import { FiltersExpiditeurComponent } from '../filters-expiditeur/filters-expiditeur.component';
import { FiltersStatusComponent } from '../filters-status/filters-status.component';
import { inputs } from '@syncfusion/ej2-angular-calendars/src/calendar/calendar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-maileva',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterMailvaComponent implements OnInit, OnChanges {
  collapseFilterBox: boolean = true;

  filtre_maileva: FilterMaileva = new FilterMaileva();
  filterParamString: string = null;
  collectionParamString: string = null;
  @Output() onPushFilters: EventEmitter<FilterMaileva> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();
  @ViewChild(FiltersCourrierComponent) FiltersCourrierComponent: FiltersCourrierComponent;
  @ViewChild(FiltersCreateursComponent) FiltersCreateursComponent: FiltersCreateursComponent;
  @ViewChild(FiltersDistinataireComponent) FiltersDistinataireComponent: FiltersDistinataireComponent;
  @ViewChild(FiltersExpiditeurComponent) FiltersExpiditeurComponent: FiltersExpiditeurComponent;
  @ViewChild(FiltersStatusComponent) FiltersStatusComponent: FiltersStatusComponent;
  @Input() filterSsearch;
  onResetCourrier: boolean = false;
  onResetDestinataire: boolean = false;
  onResetExpediteur: boolean = false;
  onResetCreateur: boolean = false;
  onResetStatut: boolean = false;
  detect: boolean = false;
  @Output() collapseCloseToparent: EventEmitter<boolean> = new EventEmitter();
  @Input() collapseFromParent: boolean = false;
  alerts: any;
  buttons: any;
  currentLanguage: string;

  constructor(
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private apiFIlterHistoryService: FilterHistoryService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.currentLanguage = this.translate.currentLang;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.currentLanguage = this.translate.currentLang;
      });
    });

    this.route.queryParamMap.subscribe((queryParams) => {
      this.filterParamString = queryParams.get('filter');
      this.collectionParamString = queryParams.get('collection');
      if (this.filterParamString !== null && this.collectionParamString !== null) {
        if (!this.filterParamString || !this.collectionParamString) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = this.alerts.invalidNumberFilter;
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          this.getFilterByID(this.filterParamString, this.collectionParamString);
          this.collapseFilterBox = true;
        }
        this.collapseFilterBox = false;
      }
    });
  }

  closefilter(event) {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  getFilterByID(IDFilter, IDCollection) {
    const unsubscribeFilterSave = this.apiFIlterHistoryService.getFiltrebyID(IDFilter, IDCollection).subscribe(
      (response) => {
        this.getFilterFromCollection(response.input);
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  getFilterFromCollection($event) {
    localStorage.setItem('filtreMaileva', JSON.stringify($event));
    this.FiltersCourrierComponent.ngOnInit();
    this.FiltersCreateursComponent.ngOnInit();
    this.FiltersDistinataireComponent.ngOnInit();
    this.FiltersExpiditeurComponent.ngOnInit();
    this.FiltersStatusComponent.ngOnInit();
    this.onPushFilters.emit($event);
    this.submitFilter();
  }

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
    this.collapseCloseToparent.emit(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterSsearch) {
      if (this.filterSsearch === true) {
        this.onResetCourrier = true;
        this.onResetDestinataire = true;
        this.onResetExpediteur = true;
        this.onResetCreateur = true;
        this.onResetStatut = true;
        this.cdRef.detectChanges();
      }
    }
    if (this.collapseFromParent) {
      this.collapseFilterBox = true;
    }
  }

  resetForm() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    localStorage.removeItem('savedFilter');
    localStorage.removeItem('savedCollection');
    this.onResetCourrier = true;
    this.onResetDestinataire = true;
    this.onResetExpediteur = true;
    this.onResetCreateur = true;
    this.onResetStatut = true;
    this.cdRef.detectChanges();
    this.onSubmitFilter.emit('reset');
    this.toggleFilterCorp();
  }

  getResetCourrier($event) {
    this.onResetCourrier = $event;
  }

  getResetCreateur($event) {
    this.onResetCreateur = $event;
  }

  getResetDestinataire($event) {
    this.onResetDestinataire = $event;
  }

  getResetExpediteur($event) {
    this.onResetExpediteur = $event;
  }

  getResetStatut($event) {
    this.onResetStatut = $event;
  }

  EmitFilterDestinataire($event: Destinataire) {
    this.filtre_maileva.destinataire = $event;
    this.EmitFilters();
  }

  EmitFilterExpediteur($event: Expediteur) {
    this.filtre_maileva.expediteur = $event;
    this.EmitFilters();
  }

  EmitFilterCreateur($event: Createur) {
    this.filtre_maileva.createur = $event;
    this.EmitFilters();
  }

  EmitFilterCourrier($event: Courrier) {
    this.filtre_maileva.courrier = $event;
    this.detect = true;
    this.EmitFilters();
  }

  EmitFilterStatus($event: Statut) {
    this.filtre_maileva.statut = $event;
    this.EmitFilters();
  }

  submitFilter() {
    if (this.detect) {
      this.onSubmitFilter.emit('maileva');
      this.detect = false;
    } else {
      this.onSubmitFilter.emit('submit');
    }
    this.toggleFilterCorp();
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filtre_maileva);
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }
}
