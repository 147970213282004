import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
 
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { FormModel } from 'src/app/entity/Formbuilder/FormModel';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';

class Config {
  key: string;
  value: any;
}
class Instance {
  report_server: string;
  subscription_url: string;
  currentCurrency: string;
  formatCurrency: string;
  country: string;
  api_url: String;
}
@Component({
  selector: 'app-add-configuration',
  templateUrl: './add-configuration.component.html',
  styleUrls: ['./add-configuration.component.css'],
})
export class AddConfigurationComponent implements OnInit, AfterContentInit {
  panelOpenState = false;
  formBuilderDataPhysique: any;
  formBuilderDataMorale: any;
  formBuilderDataOrganisme: any;
  formBuilderDataCompagnie: any;
  formBuilderDataQuality : any;
  formBuilderDataGamme : any;
  listOfUnsubscribeWebService: Subscription[] = [];
   
  config = new Config();
  keyFormOrganisme: string;
  keyFromPhysique: string;
  keyFormMorale: string;
  keyFormCompagnie: string;
  keyFormGamme: string;
  keyInstance: string;
  keyFormQuality: string;
  infoSpecif: boolean = true;
  infoSpecifEn: boolean = false;
  organisme: boolean = false;
  compagnie: boolean = false;
  instance: boolean;
  quality:boolean =false;
  gamme:boolean =false;
  formErrorphysique: boolean = false;
  formErrormorale: boolean = false;
  formErrororganisme: boolean = false;
  formErrorQuality: boolean = false;
  formErrorGamme: boolean = false;
  // subscription_url: any;
  @ViewChild('souscription_url') souscription_url;
  @ViewChild('report_server') report_server;
  @ViewChild('api_url') api_url;

  currentCurrency: string;
  formatCurrency: string;
  country: string;
  bodyInstance = new Instance();
  formulaireTrigger = false;
  urlvalid2: boolean;
  urlvalid1: boolean;
  urlvalid3: boolean;
  alerts: any;
  buttons: any;
  formErrorCompagnie: boolean;
  showLoading: boolean;
  currentLanguage: string;

  constructor(
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiConfigurationService: ApiConfigurationService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate: TranslateService
  ) {}

  ngAfterContentInit(): void {}

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.currentLanguage = this.translate.currentLang;
      this.sharedMenuObserverService.updateMenu(object.navbar.Configuration);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.currentLanguage = this.translate.currentLang;
        this.sharedMenuObserverService.updateMenu(object.navbar.Configuration);
      });
    });
    this.showloader()

    this.keyFromPhysique = environment.keyFormProspect;
    this.keyFormMorale = environment.keyFormEntreprise;
    this.keyFormOrganisme = environment.keyFormOrganisme;
    this.keyFormCompagnie = environment.keyFormCompagnie;
    this.keyFormGamme = environment.keyFormGamme;
    this.keyInstance = environment.keyInstance;
   this.keyFormQuality =environment.keyFormQuality;
    this.apiConfigurationService.getParamConfig(this.keyFromPhysique).subscribe((Response) => {
      this.formBuilderDataPhysique = Response.value;
      this.formulaireTrigger = true;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormMorale).subscribe((Response) => {
      this.formBuilderDataMorale = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormOrganisme).subscribe((Response) => {
      this.formBuilderDataOrganisme = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormCompagnie).subscribe((Response) => {
      this.formBuilderDataCompagnie = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormQuality).subscribe((Response) => {
      this.formBuilderDataQuality = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyInstance).subscribe((Response) => {
      this.bodyInstance = Response.value;
      this.hideloader()

    });
    this.apiConfigurationService.getParamConfig(this.keyFormGamme).subscribe((Response) => {
      this.formBuilderDataGamme = Response.value;
      this.hideloader()
    });
  }

  getDataPhysiqueForm(event: any): void {
    this.formBuilderDataPhysique = event;
  }

  getDataMoraleForm(event: any): void {
    this.formBuilderDataMorale = event;
  }

  getDataOrganismeForm(event: any): void {
    this.formBuilderDataOrganisme = event;
  }

  getDataCompagnieForm(event: any): void {
    this.formBuilderDataCompagnie = event;
  }
  
  getDataQualityForm(event: any): void {
    this.formBuilderDataQuality = event;
  }
  
  getDataGammeForm(event: any): void {
    this.formBuilderDataGamme = event;
  }

  ngOnDestroy(): void {
    this.apiAdminBpmServices.sharedFormbuilder = {};
    this.listOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    });
  }

  scroll(el: string): void {
    this.apiAdminBpmServices.sharedFormbuilder = {};
  
    this.organisme = false;
    this.infoSpecif = false;
    this.infoSpecifEn = false;
    this.instance = false;
    this.compagnie = false;
    this.quality = false;
    this.gamme = false;
  
    switch (el) {
      case 'organisme':
        this.organisme = true;
        break;
      case 'infoSpecifEn':
        this.infoSpecifEn = true;
        break;
      case 'infoSpecif':
        this.infoSpecif = true;
        break;
      case 'compagnie':
        this.compagnie = true;
        break;
      case 'quality':
        this.quality = true;
        break;
      case 'gamme':
        this.gamme = true;
        break;
      default:
        this.instance = true;
        break;
    }
  }
  

  addConfig(type) {
    this.showLoading = true 
    this.showloader()
    if (type === 'organisme') {
      this.config.key = this.keyFormOrganisme;
      this.config.value = this.formBuilderDataOrganisme;
      this.apiConfigurationService.updateParamConfig(this.keyFormOrganisme, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
        
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompOrganismUpdatedSuccess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    } else if (type === 'morale') {
      this.config.key = this.keyFormMorale;
      this.config.value = this.formBuilderDataMorale;
      this.apiConfigurationService.updateParamConfig(this.keyFormMorale, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
        
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompMoralUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    } else if (type === 'physique') {
      this.config.key = this.keyFromPhysique;
      this.config.value = this.formBuilderDataPhysique;
      this.apiConfigurationService.updateParamConfig(this.keyFromPhysique, this.config).subscribe(
        (Response) => {
          //  this.apiAdminBpmServices.sharedFormbuilder = {};
          

          this.apiAdminBpmServices.sharedIsSubmited = true;
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompPhysiqueUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    }if (type === 'compagnie') {
      this.config.key = this.keyFormCompagnie;
      this.config.value = this.formBuilderDataCompagnie;
      this.apiConfigurationService.updateParamConfig(this.keyFormCompagnie, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
      
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompCompagnieUpdatedSuccess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    }else if (type === 'qualite'){
      this.config.key = this.keyFormQuality;
      this.config.value = this.formBuilderDataQuality;
      this.apiConfigurationService.updateParamConfig(this.keyFormQuality, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
        
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoQualiteUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    }else if (type === 'gamme'){
      this.config.key = this.keyFormGamme;
      this.config.value = this.formBuilderDataGamme;
      this.apiConfigurationService.updateParamConfig(this.keyFormGamme, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
         
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoGammeUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    }
     else {
      this.config.key = this.keyInstance;
      this.config.value = this.bodyInstance;
      this.urlvalid1 = this.souscription_url.valueAccessor._elementRef.nativeElement.validity.valid;
      this.urlvalid2 = this.report_server.valueAccessor._elementRef.nativeElement.validity.valid;
      this.urlvalid3 = this.api_url.valueAccessor._elementRef.nativeElement.validity.valid;

      if (!this.urlvalid1 || !this.urlvalid2 || !this.urlvalid3) {
        this.alertError(this.alerts.invalidFields);
      } else {
        this.apiConfigurationService.updateParamConfig(this.keyInstance, this.config).subscribe(
          (Response) => {
           
            this.apiAdminBpmServices.sharedIsSubmited = true;
            this.showLoading = false 
            this.hideloader()
            this.alertSuccess(this.alerts.instandeUpdatedSucess);
          },
          (error) => {
            console.log(error);
            this.showLoading = false 
            this.hideloader()
            this.alertError(this.alerts.errorUpdating);
          }
        );
      }
    }
  }
  

  alertError(data, err?) {
    swal.fire({
      title: data,
      text: this.alerts.badHappened,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  alertSuccess(data, err?) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.ngOnInit();
        }
      });
  }

  onGetItemErrorPHysique($event) {
    this.formErrorphysique = $event;
  }

  onGetItemErrorMorale($event) {
    this.formErrormorale = $event;
  }

  onGetItemErrorOrganisme($event) {
    this.formErrororganisme = $event;
  }

  onGetItemErrorCompagnie($event) {
    this.formErrorCompagnie = $event;
  }

  onGetItemErrorQuality($event) {
    this.formErrorQuality = $event;
  }

  onGetItemErrorGamme($event) {
    this.formErrorGamme = $event;
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

}
