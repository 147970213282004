<div class="container">
    <ng-container>
        <!--Json Editor row-->
        <div class="row">
            <div class="col">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header w-100" id="headingOne">
                            <h2 class="mb-0">
                                <button style="width: 80%" class="btn btn-link text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                     {{'languages.formbuilderTransaltion.schemaJson' | translate}}
                                </button>
                                <button style="width: 20%; background-color: transparent" class="btn text-center" type="button" [cdkCopyToClipboard]="copyToClipboard()">
                                    <i class="copy-icon fa fa-copy"></i>
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div id="json-collapse" class="card-body json-editor-element">
                                <json-editor [options]="editorOptions" [data]="formModel.schema" (change)="onChangeJson($event)"></json-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--To drop List-->
        <div class="row">
            <div class="col-5 p-2">
                <div cdkDropList class="example-list" id="cdk-drop-list-init-0" [cdkDropListData]="toDropElements" [cdkDropListConnectedTo]="[doneList]" [cdkDropListEnterPredicate]="noReturnPredicate">
                    <div class="row">
                        <div class="col-6" *ngFor="let option of toDropElements">
                            <div  cdkDrag>
                                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                <button class="text-left color " mat-raised-button type="button" style="width:160px;margin-bottom: 10px; margin-right: 15px;">

                                    <i class="{{option.icon}}" style="margin-right: 10px; min-width: 11px"></i>
                                    {{dict[option.typename]}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7">

                <!--Drop Zone-->


                <div cdkDropList class="example-list" #doneList="cdkDropList" (cdkDropListDropped)="drop($event)" [cdkDropListData]="dropZoneCreatedModel" [cdkDropListConnectedTo]="[]">
                    <div class="row">
                        <ng-container>
                            <div class="col-12" *ngFor="let item of parentObject?.schema?.properties, index as i">
                                <div class="example-custom-option" [ngClass]="{'option-disabled':item.disabled}">
                                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                    <div>
                                        <div class="row">
                                            <div class="col-12" style="padding-right: 40px; padding-left: 40px; padding-top: 20px; padding-bottom: 20px;">
                                                <div class="row form-group">
                                                    <div class="col-2">
                                                    </div>
                                                    <div class="col-8">
                                                        <shared-form-view [item]="item"></shared-form-view>
                                                    </div>
                                                    <div style="text-align: right;" class="col-2">
                                                        <em *ngIf="item.toggle;" (click)="item.toggle=false" class="fa fa-chevron-up pull-right"></em>
                                                        <em *ngIf="!item.toggle" (click)="item.toggle=true" class="fa fa-chevron-down pull-right"></em>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.toggle" class="toggle-Wrapper" [ngClass]="{'fields-disabled':item.disabled}">
                                                    <shared-form-params [item]="item" [dropZoneCreatedModel]="dropZoneCreatedModel"></shared-form-params>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container>
                            <div class="col-12" *ngFor="let item of dropZoneCreatedModel, index as i">

                                <ng-container *ngIf="!item.disabled">
                                    <div class="example-custom-option" cdkDrag  [cdkDragDisabled]="item.disabled" [ngClass]="{'option-disabled':item.disabled}" >

                                        <div  class="close text-center" *ngIf="item.name !== 'idObject'"  (click)="deleteElementFromDropZoneList(i)">
                                            <em class="fa fa-times fa-times-no-border  closeicon" ></em></div>
                                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                        <div>
                                            <div class="row ">
                                                <!-- (mouseenter)="showElement(i)"  (mouseleave)="hideElement(i)" -->
                                                <div class="col-12 itemconfig">

                                                    <div class="row form-group">
                                                        <div class="col-2">
                                                            <div class="example-handle" style="margin-top: 10px" cdkDragHandle>
                                                                <em class="fa fa-arrows fa-lg"></em>
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <shared-form-view [item]="item" (onChangeParams)="updateItem($event, i)" (onErrorParams)="getErrorParams($event)"></shared-form-view>
                                                        </div>
                                                        <div style="text-align: right;  padding-top: 20px;" class="col-2">
                                                            <em *ngIf="item.toggle  && obligText" (click)="item.toggle=false; rebuildForm()"  class="fa fa-chevron-up pull-right"></em>
                                                            <em *ngIf="!item.toggle " (click)="item.toggle=true; rebuildForm()" class="fa fa-chevron-down pull-right"></em>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.toggle" class="toggle-Wrapper">
                                                        <shared-form-params [item]="item" (onChangeParams)="updateItem($event, i)" (onErrorParams)="getErrorParams($event)"  [dropZoneCreatedModel]="dropZoneCreatedModel"></shared-form-params>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>


                        <div class="col-12">
                            <div class="example-custom-option-empty">
                                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- View -->
    <hr>
    <div style="text-align: center;padding:10px;" class="col">
        <p style="font-size: 21px;color: #6197CB;"> {{'languages.formbuilderTransaltion.Vue' | translate}}</p>
    </div>
    <hr style="margin-bottom: 10px;">


    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <formly-form [form]="formCopie" [fields]="fieldsCopie" [model]="model"></formly-form>
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>

    </form>
</div>
